$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function(){
        if ($(this).hasClass("js-menu-close")) {
            $(".js-menu").removeClass("active");
            $(".js-navbutton").removeClass("js-menu-close active light");
            $(".js-claimframe").removeClass("light");
            window.location.hash = '#a';
            return;
        }

        $(this).toggleClass("active");
        $(".js-header").toggleClass("active-nav");
        $(".js-nav").toggleClass("active");
    });

    $(".js-nav-main a").click(function(){
        $(".js-navbutton").trigger("click");
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * toggleContent
    // *
    // *
    $(".js-togglecontent-button").click(function(){
        $(".js-togglecontent").toggleClass("active");
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * slider
    // *
    // *
    var $teaserSlider = $(".js-teaserslider");

    if ($teaserSlider.length){
        $teaserSlider.each(function(){
            $(this).slick({
                slidesToShow: 1,
                slidersToScroll: 1,
                fade: false,
                dots: false,
                arrows: true,
                rows: 0,
                prevArrow: $(this).parents(".js-teaserslider-wrapper").find(".js-teaserslider-nav-prev"),
                nextArrow: $(this).parents(".js-teaserslider-wrapper").find(".js-teaserslider-nav-next"),
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * slider
    // *
    // *
    var $slider = $(".js-slider"),
        $textSlider = $(".js-textslider");

    if ($slider.length){
        $slider.slick({
            slidesToShow: 1,
            slidersToScroll: 1,
            fade: false,
            dots: false,
            arrows: true,
            rows: 0,
            prevArrow: $(".js-slider-nav-prev"),
            nextArrow: $(".js-slider-nav-next"),
            asNavFor: $textSlider,
        });

        $textSlider.slick({
            slidesToShow: 1,
            slidersToScroll: 1,
            fade: true,
            dots: false,
            arrows: false,
            rows: 0,
            asNavFor: $slider,
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * heroIntro lotti animation
    // *
    // *

    // landscape Mode or else Portrait: change animation
    if ($(window).width() >= $(window).height()) {
        var lottiFilePath = $(".js-lottie-animation").data("src");
    }else {
        var lottiFilePath = $(".js-lottie-animation").data("src-portrait");
    }
    console.log(lottiFilePath);

    LottieScrollTrigger({
        target: ".js-lottie-animation",
        path: lottiFilePath,
        speed: "medium",
        scrub: 1,
        trigger: ".js-lottie-wrapper",
        pin: true,
        markers: false,
        onUpdate: function(self) {
            if (self.progress > 0.95) {
                $(".js-claimframe").addClass("active");
                $(".js-asidebutton").addClass("active");
            }
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * hide elements by reaching bottom site
    // *
    // *
    $(window).scroll(function() {
        if($(window).scrollTop() >= ($(document).height() - ($(window).height() + 300))) {
            $(".js-asidebutton").removeClass("active");
            $(".js-claimframe").addClass("page-bottom");
        }else {
            $(".js-asidebutton").addClass("active");
            $(".js-claimframe").removeClass("page-bottom");
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * popup
    // *
    // *
    $(".js-popup-close").click(function(){
        $(".js-popup-table").fadeOut();
        window.location.hash = '#a';
    });




});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * menu and reservation overlay
// *
// *
$(window).on('hashchange load', function (event) {
    var hash = window.location.hash.split('#')[1];

    if (hash == 'menu') {
        event.preventDefault();
        $(".js-menu").addClass("active");
        $(".js-navbutton").addClass("js-menu-close active light");
        $(".js-claimframe").addClass("light");
    }

    if (hash == 'reservation') {
        event.preventDefault();
        $(".js-popup-table").fadeIn();
    }
});



